import React from "react";
import classnames from "classnames";
import "./Avatar.css";

const Avatar = ({ user, mini }) => (
  <div
    className={classnames("Avatar", {
      "is-small": !!mini
    })}
  >
    {!!user.facebookPicture ? (
      <img
        className="Avatar_picture"
        src={user.facebookPicture}
        alt="Profile Pic"
      />
    ) : (
      <div className="Avatar_picture">
        {user.facebookName
          .split(" ")
          .map(name => name.charAt(0))
          .join("")}
      </div>
    )}
    {!mini && <span className="Avatar_name">{user.facebookName}</span>}
  </div>
);

export default Avatar;
