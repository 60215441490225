import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { vote } from "../redux/actions";
import Button from "../components/Button";
import Card from "../components/Card";
import FacebookLogin from "../components/FacebookLogin";
import Widget from "../components/Widget";
import loader from "./loader.svg";

import "./Server.css";
import MrWhiteModal from "../components/MrWhiteModal";

const games = `
  games {
    word
    id
    players {
      id
      status
      user {
        id
        facebookName
        facebookPicture
      }
      number
      role
      score
    }
    player_number
    status
    rounds {
      id
      eliminated{
        id
      }
      status
      starting_from
      playersInDraw {
        id
      }
      votes {
        from {
          id
        }
        for {
          id
        }
      }
    }
  }
`;

const SERVER = gql`
  query Server($slug: String!) {
    server(slug: $slug) {
      id
      slug
      ${games}
    }
  }
`;

const REGISTER = gql`
  mutation Register($gameId: ID!) {
    register(gameId: $gameId) {
      status
    }
  }
`;

const VOTE_AGAINST = gql`
  mutation VoteAgainst($roundId: ID!, $playerId: ID!) {
    voteAgainst(roundId: $roundId, playerId: $playerId) {
      status
    }
  }
`;

// const GAMES_SUBSCRIPTION = gql`
//   subscription games {
//     ${games}
//   }
// `;

const Server = props => {
  const { serverSlug } = useParams();
  const { loading, data, startPolling, stopPolling, refetch } = useQuery(
    SERVER,
    {
      variables: { slug: serverSlug }
    }
  );
  const [register] = useMutation(REGISTER, { onCompleted: refetch });
  const [vote_against] = useMutation(VOTE_AGAINST, {
    onCompleted: () => {
      startPolling(500);
    }
  });

  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.user);

  let currentVote = useSelector(state => state.vote);
  let alreadyVoted = false;
  // const { data: dataGames } = useSubscription(GAMES_SUBSCRIPTION, {
  //   onSubscriptionData: data => console.log(data)
  // });

  const loaded = typeof data !== "undefined";

  const currentGame = loaded
    ? data.server.games[data.server.games.length - 1]
    : null;

  const currentRound = loaded
    ? currentGame.rounds[currentGame.rounds.length - 1]
    : null;

  const currentPlayer =
    loaded && !!currentUser
      ? currentGame.players.find(player => player.user.id === currentUser.id)
      : null;

  const isInTheGame = !!currentPlayer;

  if (loaded && currentRound.votes && isInTheGame) {
    const currentUserVote = currentRound.votes.find(
      vote => vote.from.id === currentPlayer.id
    );
    if (!!currentUserVote) {
      alreadyVoted = true;
      startPolling(500);
      dispatch(vote(currentUserVote.for.id));
    }
  }

  const currentRoundId = !!currentRound ? currentRound.id : 0;

  useEffect(() => {
    dispatch(vote(null));
  }, [currentRoundId]);

  useEffect(() => {
    if (!isInTheGame && loaded && !!currentUser) {
      register({ variables: { gameId: currentGame.id } });
    }
  }, [isInTheGame, loaded, register, currentUser]);

  if (!loaded) return <div className="Server">{loading && "LOADING"}</div>;

  const empty_slots = currentGame.player_number - currentGame.players.length;

  return (
    <div className="Server">
      {!!currentRound &&
        currentRound.status === "WAITING_FOR_MR_WHITE_INPUT" &&
        currentRound.eliminated &&
        currentPlayer &&
        currentRound.eliminated.id === currentPlayer.id && (
          <MrWhiteModal gameId={currentGame.id} />
        )}
      <main className="Server_main">
        <Widget className="Server_game">
          {currentGame.players
            .filter(player => player.status === "PLAYING")
            .map((player, index) => (
              <Card
                key={index}
                currentGame={currentGame}
                typing={
                  !!currentRound &&
                  currentRound.status === "WAITING_FOR_MR_WHITE_INPUT" &&
                  currentRound.eliminated &&
                  currentPlayer &&
                  currentRound.eliminated.id === player.id
                }
                canVote={
                  !alreadyVoted &&
                  currentPlayer &&
                  currentPlayer.status === "PLAYING"
                }
                {...player}
              />
            ))}
          {currentGame.players
            .filter(player => player.status !== "PLAYING")
            .map((player, index) => (
              <Card
                disabled
                key={index}
                currentGame={currentGame}
                canVote={false}
                {...player}
              />
            ))}

          {empty_slots > 0 && (
            <div className="Server_loading">
              <img src={loader} className="Card_loader" alt="loader" />
              <span>
                En attente de {empty_slots} joueur{empty_slots > 1 && "s"}
              </span>
            </div>
          )}
        </Widget>
        <div className="Server_footer">
          <div className="Server_word">
            mot attribué
            <br />
            <span>{currentGame.word}</span>
          </div>
          {!alreadyVoted && (
            <Button
              onClick={() =>
                !!currentPlayer
                  ? vote_against({
                      variables: {
                        roundId: currentRound.id,
                        playerId: currentVote
                      }
                    })
                  : false
              }
              disabled={!currentVote}
            >
              Valider le vote
            </Button>
          )}

          {alreadyVoted && <span>Vote validé !</span>}
        </div>
      </main>
      <Widget className="Server_sidebar">
        <FacebookLogin />
      </Widget>
    </div>
  );
};

export default Server;
