import React from "react";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Widget from "./Widget";
import Button from "./Button";

import "./MrWhiteModal.css";

const GUESS = gql`
  mutation Guess($guess: String, $gameId: ID!) {
    guess(guess: $guess, gameId: $gameId) {
      status
    }
  }
`;

const MrWhiteModal = ({ gameId }) => {
  let input;
  const [guess] = useMutation(GUESS, {
    onCompleted: args => {
      console.log(args);
    }
  });
  return (
    <div className="MrWhiteModal">
      <Widget className="MrWhiteModal_content">
        <p>Tentez de deviner le mot de la majorité</p>
        <input
          type="text"
          ref={node => {
            input = node;
          }}
        />
        <Button
          onClick={() => {
            guess({
              variables: {
                guess: input.value,
                gameId
              }
            });
          }}
        >
          Valider
        </Button>
      </Widget>
    </div>
  );
};

export default MrWhiteModal;
