import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider } from "react-redux";

import store from "./redux/store";
import Home from "./pages/Home";
import Server from "./pages/Server";
import client from "./apolloClient";

function App() {
  return (
    <Router>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <div className="App">
            <Switch>
              <Route path="/game/:serverSlug">
                <Server />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </ApolloProvider>
      </Provider>
    </Router>
  );
}

export default App;
