import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FacebookLoginButton from "react-facebook-login";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { login } from "../redux/actions";
import Avatar from "./Avatar";

const AUTHENTICATE = gql`
  mutation Authenticate($facebookToken: String!) {
    authenticate(facebookToken: $facebookToken) {
      user {
        id
        facebookId
        facebookName
        facebookPicture
      }
      token
    }
  }
`;

const FacebookLogin = () => {
  const [authenticate, { data }] = useMutation(AUTHENTICATE);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  // TEMP OFFLINE
  // useEffect(() => {
  //   authenticate({ variables: { facebookToken: "accessToken" } });
  // }, []);

  useEffect(() => {
    if (typeof data !== "undefined") {
      dispatch(login(data.authenticate.user));
      localStorage.setItem("token", data.authenticate.token);
    }
  }, [data, dispatch]);
  return user ? (
    <Avatar user={user} />
  ) : (
    <FacebookLoginButton
      appId="1072829149761143"
      autoLoad={true}
      fields="name,email,picture"
      callback={({ accessToken }) => {
        authenticate({ variables: { facebookToken: accessToken } });
      }}
    />
  );
};

export default FacebookLogin;
