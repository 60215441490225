import ApolloClient from "apollo-client";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink, split } from "apollo-link";
// import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
// import { getMainDefinition } from "apollo-utilities";

const refreshToken = async () => {
  // const axios = require("axios").default;
  // const response = await axios({
  //   method: "post",
  //   url: "http://8xrm.newsgang.com/8sgql/login",
  //   data: {
  //     login: "ADMIN",
  //     password: "ADM"
  //   }
  // })
  // localStorage.setItem("token", response.data.token);
  // return response.data.token
  console.log("REFRESH TOKEN");
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.log(networkError);
  }
  if (graphQLErrors) {
    console.log(graphQLErrors);
    if (graphQLErrors[0].message === "Unauthorized !!!") {
      refreshToken();
    }
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_HOST
});

// const wsLink = new WebSocketLink({
//   uri: process.env.REACT_APP_API_SOCKET_HOST,
//   options: {
//     reconnect: true
//   }
// });

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   httpLink
// );

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem("token");

  if (token == null) {
    token = refreshToken();
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
});

export default client;
