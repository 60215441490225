import React from "react";
import classnames from "classnames";
import "./Widget.css";

const Widget = ({ className, children, ...props }) => (
  <div className={classnames("Widget", className)} {...props}>
    {children}
  </div>
);

export default Widget;
