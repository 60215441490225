import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import FacebookLogin from "../components/FacebookLogin";
import "./Home.css";

const CREATESERVER = gql`
  mutation CreateServer {
    createServer {
      slug
    }
  }
`;

const Home = () => {
  const [createServer, { data }] = useMutation(CREATESERVER);
  const history = useHistory();
  const currentUser = useSelector(state => state.user);

  useEffect(() => {
    if (typeof data !== "undefined") {
      history.push(`/game/${data.createServer.slug}`);
    }
  }, [data]);
  return (
    <div className="Home">
      <h1 className="Home_title">Undercovid</h1>
      {!!currentUser && <p>Bonjour {currentUser.facebookName},</p>}
      {!!currentUser && (
        <Button href="#" onClick={createServer}>
          Nouvelle Partie
        </Button>
      )}
      {!currentUser && <FacebookLogin />}
    </div>
  );
};

export default Home;
