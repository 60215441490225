import React from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { vote } from "../redux/actions";
import Avatar from "./Avatar";
import "./Card.css";

import fucking from "./fucking.svg";
import wondering from "./wondering.svg";

const Card = ({ id, user, role, canVote, currentGame, score, disabled }) => {
  const currentUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const isCurrentUser = !!currentUser && !!user && currentUser.id === user.id;
  const votable = !isCurrentUser && canVote;
  const currentVote = useSelector(state => state.vote);
  const votesAgainst =
    !!currentGame &&
    !!currentGame.rounds &&
    !!currentGame.rounds.length &&
    !!currentGame.rounds[currentGame.rounds.length - 1] &&
    !!currentGame.rounds[currentGame.rounds.length - 1].votes
      ? currentGame.rounds[currentGame.rounds.length - 1].votes
          .filter(vote => vote.for.id === id)
          .map(vote =>
            currentGame.players.find(player => player.id === vote.from.id)
          )
      : null;
  return (
    <div
      className={classnames("Card", {
        "is-votable": votable,
        "is-disabled": disabled,
        "voted-against": currentVote === id
      })}
      onClick={() => (votable ? dispatch(vote(id)) : false)}
    >
      {/* <div class="Card_number">{number}</div> */}
      <Avatar user={user} />
      {disabled && <span className="Card_Role">{role}</span>}
      <span className="Card_Score">
        {score}pt{score > 1 ? "s" : ""}
      </span>
      {/* {!loading && isCurrentUser && (
        <div className="Card_Role">
          <div>
            <strong>Role : </strong>
            {user.role}
          </div>
          <div>
            <strong>Mot : </strong>Kikou
          </div>
        </div>
      )} */}
      <div className="Votes">
        {!!votesAgainst && (
          <div className="Votes_Number">
            {votesAgainst.map(player => (
              <Avatar user={player.user} key={player.user.id} mini />
            ))}
          </div>
        )}
        <img
          src={currentVote === id ? fucking : wondering}
          className="Voted"
          alt="vote"
        />
      </div>
    </div>
  );
};

export default Card;
